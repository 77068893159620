import { template as template_964c0ad5f8df44f0a9c7b0c6e7837c03 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_964c0ad5f8df44f0a9c7b0c6e7837c03(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
