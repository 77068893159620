import { template as template_69aa420439514d7bb5cb439a1f0f9856 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_69aa420439514d7bb5cb439a1f0f9856(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
