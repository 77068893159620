import { template as template_296e2d15f39843858bfba1fb9bff1f9c } from "@ember/template-compiler";
import { on } from "@ember/modifier";
const BulkSelectCell = template_296e2d15f39843858bfba1fb9bff1f9c(`
  <td class="bulk-select topic-list-data">
    <label for="bulk-select-{{@topic.id}}">
      <input
        {{on "click" @onBulkSelectToggle}}
        checked={{@isSelected}}
        type="checkbox"
        id="bulk-select-{{@topic.id}}"
        class="bulk-select"
      />
    </label>
  </td>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectCell;
