import { template as template_37890f59f4374d1c98efdbea46da63c0 } from "@ember/template-compiler";
const FKText = template_37890f59f4374d1c98efdbea46da63c0(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
